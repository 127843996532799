.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 250px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s all;
}
.card:hover {
    transform: scale(1.03);
}
.card img {
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.description {
    background: var(--blue);
    width: 100%;
    height: 100px;
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.card p {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
}

.cardContainer a {
    background: var(--blue);
    padding: 15px 30px;
    border-radius: 30px;
    text-transform: uppercase;
    transition: 0.4s all;
}
.cardContainer a:hover {
    background: #fff;
    color: var(--blue);
}
.cardContainer a .icon path {
    stroke: white;
}

.cardContainer a:hover > .icon path {
    stroke: var(--blue);
}

.cardContainer a .icon {
    padding-left: 5px;
    font-size: 12px;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    max-width: 1200px;
    margin: auto;
    padding: 4rem 3rem;

    justify-items: center;
    align-items: start;
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 70%;
        padding: 0;
    }
}

@media only screen and (max-width: 576px) {
    .container {
        max-width: 90%;
        padding: 0;
    }
}

/* .card{
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 2rem;
  max-width: 1200px;
  margin: auto;
  padding: 4rem 3rem;

  justify-items: center;
  align-items: center;
} */
