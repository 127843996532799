.productsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 2rem;
  max-width: 1200px;
  margin: auto;
  padding: 4rem 3rem;

  justify-items: center;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: #182333;
  padding: 0 1.5rem;
  margin-top: 5rem;
  margin-left: 2rem;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  display: block;
  width: 6px;
  height: 100%;
  background-color: var(--blue);
  position: absolute;
  top: 0;
  left: 0;
}

.productsCard {
  height: 270px;
  width: 270px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s all;
  background-color: white;
}
.productsCard > img {
  height: 160px;
  object-fit: cover;

  border-radius: 10px 10px 0 0;
}
.productsCard:hover {
  transform: scale(1.03);
}

.description {
  background-color: var(--blue);
  width: 100%;
  height: 110px;
  border-radius: 0 0 10px 10px;
  position: relative;
}
.productName {
  background: var(--blue);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0.5rem;
  display: block;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #f7f7f7;
}
.btn {
  display: flex;
  border: 2px solid #f7f7f7;
  color: #f7f7f7;
  width: 7rem;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0.2rem;
  position: absolute;
  left: 10px;
  bottom: 11px;
  letter-spacing: 0.4px;
  word-spacing: 1px;
  font-weight: 500;

  transition: 0.4s all;
}
.btn:hover {
  border: 2px solid #f7f7f7;
  background-color: #f7f7f7;
  color: var(--blue);
}

@media only screen and (max-width: 768px) {
  .productsContainer {
    grid-template-columns: unset;
  }
  .productsCard {
    max-width: 400px;
    width: 100%;
  }
  .title {
    font-size: 28px;
    margin: 2rem 1rem 0;
  }
}

@media only screen and (max-width: 576px) {
  .productsContainer {
    grid-template-columns: unset;
    padding: 3rem 2rem;
  }
  .title {
    font-size: 22px;
    /* margin: 2rem 1rem 0; */
  }
}
