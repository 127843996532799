.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 2rem;
  max-width: 80%;
  margin: auto;
  padding: 4rem 0;
  gap: 50px;
  justify-items: start;
  align-items: start;
}

.leftSide {
  display: flex;
  flex-direction: column;
}
.content {
  font-size: 16px;
  line-height: 1.6em;
  letter-spacing: 0.3px;
  color: var(--gray);
  padding-bottom: 30px;
}
.content a {
  color: var(--blue);
  font-size: 16px;
  font-weight: 700;
}
form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

form input {
  width: 40%;
  padding: 10px 10px;
  border: 1px solid var(--gray);
  border-radius: 8px;
}
form textarea {
  width: 94%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid var(--gray);
  border-radius: 8px;
  resize: none;
}
form button {
  width: 40%;
  padding: 10px;
  border: 1px solid var(--gray);
  border-radius: 8px;
  color: #fff;
  background: var(--blue);
  font-size: 16px;
  transition: 0.4s all;
}
form button:hover {
  color: var(--blue);
  background: #fff;
  cursor: pointer;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightSide a {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.9em;
  color: var(--gray);
  display: flex;
  align-items: center;
  gap: 15px;
  transition: 0.4s all;
}
.rightSide a:hover {
  color: var(--blue);
}
.mapFrame iframe {
  width: 100%;
  height: 270px;
  border: 0;
}
@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 90%;
  }
}

@media screen and (max-width: 993px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    max-width: 95%;
  }
  form input {
    width: 39%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 80%;
    justify-items: center;
  }
  form textarea {
    width: 93%;
  }
  form button {
    width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .container {
    grid-template-columns: unset;

    max-width: 90%;
  }
  form input {
    width: 80%;
  }
  form textarea {
    width: 85%;
  }
  form button {
    width: 85%;
  }
  .content {
    font-size: 13px;
  }
  .content a {
    font-size: 13px;
  }
  .rightSide a {
    font-size: 16px;
  }
}
