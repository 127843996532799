.header {
  text-align: center;
  padding-top: 60px;
  position: relative;
  padding-bottom: 50px;
}
.header h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  padding-bottom: 8px;
  text-transform: uppercase;
}
.header::after {
  content: "";
  position: absolute;
  background: var(--blue);
  width: 70px;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
}
