.wrapper {
  padding-inline: 1rem;
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 5rem auto 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffffff;
  padding: 3rem 1rem;
}

.image {
  width: 75px;
  height: 75px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  font-size: 30px;
  margin-bottom: 2rem;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 1rem;
}

.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin-bottom: 1rem;
}

.image:nth-child(1) {
  color: white;
}

.link a {
  font-size: 13px;
  color: var(--blue);
  font-weight: 600;
}

.link span {
  font-size: 17px;
  color: var(--blue);
  position: relative;
  top: 4px;
  left: 14px;
}
.link {
  cursor: pointer;
}
.link:hover {
  color: var(--black);
}

@media only screen and (max-width: 576px) {
  .title {
    font-size: 18px;
  }
  .content {
    font-size: 13px;
  }
}
