.wrapper {
  margin: 4rem auto;
  max-width: 1300px;
  padding-inline: 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #00000029;
}

.header h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  color: var(--black);
  text-align: left;
  text-transform: uppercase;
  flex: 1;
  flex-basis: 0;

  animation: slide-in-h2 0.7s ease-out;
}
@keyframes slide-in-h2 {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
.image {
  flex: 1;
  flex-basis: 0;

  box-shadow: 10px 10px 21px 0px rgb(0 0 0 / 9%),
    -2px -2px 5px rgb(255 255 255 / 30%);
}

.image {
  animation: slide-in-image 0.7s ease-in-out;
}

@keyframes slide-in-image {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.tableWrapper h2 {
  text-align: left;
  font-size: 26px;
  line-height: 48px;
  font-weight: 600;
  color: #555555;
  padding: 0 2rem 2rem;
  text-transform: uppercase;
  margin-top: 2rem;
}
.tableContainer {
  width: 100%;
  max-width: 1300px;
  overflow: auto;

  animation: slide-in-table 0.8s ease-in;
}
@keyframes slide-in-table {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.table {
  border-collapse: collapse;
  text-align: left;
  min-width: 1300px;
}

/* Style the scrollbar */
.tableContainer::-webkit-scrollbar {
  height: 8px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #919191;
}
/* ********************** */

.tableHead th {
  background-color: var(--blue);
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
}
.table tr {
  border-bottom: 3px solid white;
}

.table tr:nth-child(even) {
  background-color: #f0f0f0;
}

.table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  min-height: 30px !important;
  text-align: left;
}

.td1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  text-align: start;
  overflow-wrap: break-word;
  font-weight: 700 !important;
  font-size: 14px;
}

.td1 img:nth-of-type(1) {
  width: 45px;
  height: auto;
  object-fit: contain;
}
.td1 img:nth-of-type(2) {
  width: 30px;
  height: 32px;
  object-fit: contain;
}
.td1 > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.td2 > div {
  width: fit-content;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .header h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 2rem;
  }
  .header h2 {
    order: 1;
    animation: unset;
  }
  .image {
    order: 2;
    animation: unset;
  }
  .tableWrapper h2 {
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 576px) {
  .wrapper {
    margin: 3rem auto 2rem;
    padding-inline: 1.5rem;
  }
  .header {
    padding-bottom: 2rem;
  }
  .header h2 {
    font-size: 24px;
  }
  .image {
    order: 2;
    animation: unset;
  }
  .tableWrapper {
    padding-block: 1rem;
  }
  .tableWrapper h2 {
    font-size: 18px;
    padding: 0 1rem 1rem;
    margin-top: 1rem;
  }
  .table td {
    line-height: 20px;
  }
  .tableHead th {
    line-height: 22px;
  }
}
