.container {
    display: flex;
    justify-content: space-evenly;
    max-width: 70%;
    margin: auto;
    gap: 25px;
}

.videoContainer {
    display: flex;
    flex-direction: column;
}

.video {
    width: 760px !important;
    height: 427px !important;
}

.imagesContainer {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.image1 {
    width: 365px;
    height: 250px;
}

.image2 {
    width: 365px;
    height: 250px;
}

.description {
    padding: 25px 5px;
}

.description p {
    font-size: 14px;
    line-height: 27px;
    color: var(--gray);
    margin: 0 0 15px;
}

@media only screen and (max-width: 1200px) {
    .video {
        width: 586px !important;
        height: 330px !important;
    }
    .image1 {
        width: 278px;
        height: 185px;
    }

    .image2 {
        width: 278px;
        height: 185px;
    }
}

@media only screen and (max-width: 992px) {
    .container {
        max-width: 70%;
    }
    .video {
        width: 480px !important;
        height: 270px !important;
    }
    .image1 {
        width: 225px;
        height: 150px;
    }

    .image2 {
        width: 225px;
        height: 150px;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        max-width: 90%;
    }
    .videoContainer {
        align-items: center;
    }
    .video {
        max-width: 544px !important;
        height: 306px !important;
    }
    .image1 {
        width: 100%;
        height: auto;
    }

    .image2 {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 576px) {
    .video {
        width: 350px !important;
        height: 200px !important;
    }
}
