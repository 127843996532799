.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1150px;
  margin: 5rem auto 0;
}

.rightSide,
.leftSide {
  padding-inline: 1rem;
}

.leftSide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerTitle {
  margin-bottom: 2rem;
  position: relative;
  padding-left: 2rem;
}

.headerTitle::after {
  content: "";
  display: block;
  width: 6px;
  height: 100%;
  background-color: var(--blue);
  position: absolute;
  top: 0;
  left: 0;
}

.headerTitle h4 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: var(--gray);
}

.headerTitle h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
}

.content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin-bottom: 1rem;
}

.link a {
  font-size: 13px;
  color: var(--blue);
  font-weight: 600;
}

.link span {
  font-size: 17px;
  color: var(--blue);
  position: relative;
  top: 4px;
  left: 14px;
}

@media only screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .leftSide {
    max-width: 700px;
    padding-inline: 2rem;
  }
  .rightSide {
    padding-inline: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .headerTitle h4 {
    font-size: 13px;
  }

  .headerTitle h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 576px) {
  /* .headerTitle h4 {
    font-size: 13px;
  } */
  .container {
    gap: 1rem;
  }
  .leftSide {
    padding-inline: 1rem;
  }
  .rightSide {
    padding-inline: 1rem;
  }
  .headerTitle h2 {
    font-size: 18px;
  }
  .content p {
    font-size: 13px;
  }
}
