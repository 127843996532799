.NavbarContainer {
  background: var(--blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  position: sticky;
  top: 60px;
  z-index: 2;
  gap: 3rem;
  box-shadow: -7px 7px 8px -7px #000000bd;
}

.menuList {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-left: auto;
}

.menuList li a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 0.2px;
  width: max-content;
  display: inline-block;
}

.productsListContainer {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 999;
  left: 0;
  padding-top: 1.5rem;
}

.products:hover > .productsListContainer {
  display: block;
  transition: 0.5s all ease-out;
}

.productsList {
  background-color: var(--blue);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
}

.productsList li {
  padding: 15px 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.342);
  cursor: pointer;
}

.productsList li a {
  display: inline-block;
  width: 100%;
  height: 22px;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.productsList li:hover {
  background: #fff;
  transition: 0.3s all ease-out;
}

.productsList li:hover a {
  color: #000;
}

.iconContainer {
  border-radius: 50%;
  background: #437893;
  width: 30px;
  min-width: 30px;

  height: 30px;
  padding: 2px;
  cursor: pointer;
  position: relative;
}
.iconContainer:hover {
  background: var(--blue);
}

.searchIcon {
  color: #fff;
  font-size: 23px;
  position: relative;
  top: 4px;
  left: 5px;
}
.closeIcon {
  color: #fff;
  font-size: 28px;
  position: relative;
  top: 1px;
  left: 1px;
}

.productsItem {
  color: #fff;
  font-size: 14px;
  margin-left: 2px;
}

.active {
  color: var(--black);
  font-weight: 700 !important;
}
.logo {
  width: 110px;
  height: 110px;
  background: radial-gradient(
    circle at center,
    #ffffff 21%,
    #dadada,
    #a9a9a9,
    #ffffff
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24px 6px #10304061;
}
.logo img {
  width: 100px;
  object-fit: contain;
}
@media only screen and (max-width: 1200px) {
  .menuList {
    gap: 1.5rem;
  }
  .menuList li a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 992px) {
  .productsListContainer ul {
    display: none;
  }
  .NavbarContainer {
    padding: 3.5rem 1.2rem;
    top: 0;
    gap: 1rem;
    height: 70px;
    box-sizing: border-box;
  }
  .menuList {
    max-width: 600px;
    overflow-x: auto;
    gap: 10px;
    padding-block: 10px;
  }

  .menuList::-webkit-scrollbar {
    height: 5px;
  }

  .menuList::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.195);
    border-radius: 4px;
  }

  .menuList::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.377);
    border-radius: 4px;
  }

  .menuList::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.548);
  }
  .menuList li {
    padding-inline: 0.5rem;
  }
  .menuList li a {
    display: inline-block;
    text-align: center;
  }

  .products a > svg {
    display: none;
  }
  .logo {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
  .logo img {
    width: 70px;
  }
}

@media only screen and (max-width: 576px) {
  .menuList {
    max-width: 600px;
    overflow-x: auto;
    gap: 10px;
    padding-block: 10px;
  }
  .logo {
    width: 70px;
    min-width: 70px;
    height: 70px;
  }
  .logo img {
    width: 60px;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
.NavbarContainer .products:hover .productsListContainer {
  animation: slideDown 0.5s ease-in-out;
}
