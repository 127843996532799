.container {
  padding: 2rem 3rem;
  max-width: 1300px;
  margin: auto;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  position: relative;
}
.title::after {
  content: "";
  position: absolute;
  background: var(--blue);
  width: 70px;
  height: 6px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.main {
  padding: 2rem 1rem 0;
}
.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin-bottom: 2rem;
}
.content p {
  margin-bottom: 1rem;
}
.content > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 1rem;
}
.content svg {
  font-size: 30px;
  color: var(--blue);
}
.head {
  margin-block: 0.5rem;
  background: var(--blue);
  color: #f7f7f7;
  padding-inline: 1.6rem 0.7rem;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.head h3 {
  font-size: 16px;
  font-weight: 600;
}
.head span {
  position: relative;
  top: 6px;
  font-size: 40px;
}
.head span svg {
  transition: 0.5s all;
}
.head span svg:hover {
  transform: scale(1.1);
}
.body h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin-left: 2rem;
}
.list {
  margin-left: 1rem;

  padding: 2rem;
}
.list li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 1rem;
}
.list li p {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
}
.list li svg {
  position: relative;
  top: 2px;
  font-size: 20px;
  color: var(--blue);
}

.longText {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-out;
}
.expanded {
  max-height: 100rem;
  transition: max-height 3s ease-out;
}

@media only screen and (max-width: 992px) {
  .content {
    text-align: center;
  }
  .content ul span {
    display: none;
    text-align: center;
  }
  .content ul li {
    display: inline-block;
    transition: 0.5s all;
  }
  .content ul li:hover {
    color: var(--black);
    font-weight: 400;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0rem 2rem;
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }

  .head {
    padding-block: 0.4rem;
  }
  .head h3 {
    font-size: 14px;
    font-weight: 500;
  }
  .body h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    /* margin-left: 2rem; */
  }
  .list li {
    margin-bottom: 0.5rem;
  }
  .list li p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .list li svg {
    top: 1px;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 0 1rem 2rem;
  }
  .title {
    font-size: 18px;
    line-height: 30px;
  }
  .content {
    font-size: 13px;
  }

  .head {
    gap: 1rem;
  }
  .head h3 {
    font-size: 13px;
  }
  .body h5 {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-inline: 1rem;
    text-align: justify;
  }
  .list {
    padding: 1rem;
    margin-left: 0;
  }
  .list li {
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
  .list li p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  .list li svg {
    top: 1px;
  }
}
