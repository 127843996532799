.container {
  padding: 4rem 3rem;
  max-width: 1300px;
  margin: auto;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  position: relative;
}
.title::after {
  content: "";
  position: absolute;
  background: var(--blue);
  width: 70px;
  height: 6px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.firstSection {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto auto;
}

.firstSection .content {
  grid-column: 1/1;
  grid-row: 1/2;
}
.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
}
.content p {
  margin-bottom: 1rem;
}
.content,
.image,
.certificates {
  padding-inline: 1rem;
}
.firstSection .image {
  grid-column: 1/1;
  grid-row: 2/3;
}
.firstSection .certificates {
  grid-column: 2/3;
  grid-row: 1/3;
}
.certificatesContainer {
  display: grid;
  gap: 5rem 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
  justify-items: center;
}
.certificateCard {
  width: 150px;
  height: 150px;
  border: 3px solid var(--blue);
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: var(--blue);
}
.certificateCard img {
  height: 120px;
  object-fit: contain;
}
.longText {
  max-height: 0;
  overflow: hidden;
  display: inline-block;

  transition: max-height 1s ease-out;
}
.expanded {
  max-height: 100rem;
  transition: max-height 3s ease-out;
}

.content button {
  background: transparent;
  padding: 0.5rem 1.5rem;
  border: 3px solid var(--blue);
  color: var(--blue);
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  text-transform: inherit;

  transition: 0.5s all;
}
.content button:hover {
  background: var(--blue);
  /* border: 3px solid var(--blue); */
  color: #f7f7f7;
}

@media only screen and (max-width: 992px) {
  .firstSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .content {
    text-align: center;
  }
  .image {
    max-width: 800px;
  }
  .certificatesContainer {
    display: flex;
    max-width: 900px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 3rem 2rem;
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
  }
  .firstSection {
    margin-top: 1rem;
  }
  .certificateCard {
    width: 100px;
    height: 100px;

    padding: 0.5rem;
  }
  .certificateCard img {
    height: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 2rem 1rem;
  }
  .title {
    font-size: 18px;
  }
  .content {
    font-size: 13px;
  }
}
