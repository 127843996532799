.container {
  padding: 2rem 3rem;
  max-width: 1300px;
  margin: auto;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  position: relative;
}
.title::after {
  content: "";
  position: absolute;
  background: var(--blue);
  width: 70px;
  height: 6px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.main {
  padding: 2rem 1rem;
  display: flex;
  align-items: stretch;
  gap: 2rem;
}
.content {
  width: 70%;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
}

.content span a {
  color: var(--blue);
  font-weight: 600;
}
.image {
  width: 30%;
}
.image > img {
  object-fit: cover;
}

@media only screen and (max-width: 992px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
  .content {
    order: 2;
    text-align: center;
    width: unset;
  }
  .image {
    width: unset;
    max-width: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0rem 2rem;
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    padding: 3rem 0 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 0 1rem 2rem;
  }
  .title {
    font-size: 18px;
    line-height: 30px;
    padding: 0;
  }
  .content {
    font-size: 13px;
  }
  /* .image > img {
    max-width: 350px;
    width: unset;
  } */
}
