.scrollToTopArrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--blue);
  color: #fff;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
  box-shadow: 0px 0px 6px #29292994;
  border: 3px solid white;
}

.scrollToTopArrow.show {
  opacity: 1;
  visibility: visible;
}
