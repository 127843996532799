.topbar_container {
  background: var(--blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 2;
}
.left_side {
  display: flex;
  gap: 30px;
}

a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  margin-left: 2px;
}
.phoneIcon {
  color: #fff;
  font-size: 13px;
  position: relative;
}
.emailIcon {
  color: #fff;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.linkedinIcon {
  font-size: 14px;
  position: relative;
  top: 3px;
}

.linkedin {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}
.linkedin::before {
  content: "";
  height: 25px;
  width: 2px;
  background-color: rgb(121 166 188);
  display: block;
  position: absolute;
  left: -8px;
  top: 0px;
  border-radius: 50%;
}

.right_side {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  visibility: hidden;
}
.right_side div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_side svg {
  cursor: pointer;
}

.phone_contact {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  top: 3px;
}
.email_contact {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  top: 1px;
}

/* large devices */

@media only screen and (max-width: 768px) {
  .phone_contact a span {
    display: none;
  }
  .email_contact a span {
    display: none;
  }
  .topbar_container {
    position: static;
    padding: 0.8rem 1.2rem;
  }
}
