.firstRow {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;
    max-width: 1200px;
    margin: auto;
    padding: 0 3rem;
    justify-items: center;
    align-items: center;
}
.secondRow {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;
    max-width: 1200px;
    margin: auto;
    padding: 0 3rem;
    justify-items: center;
    align-items: center;
}

.firstRow > img {
    width: 152px;
    height: auto;
    object-fit: cover;
}
.secondRow > img {
    width: 152px;
    height: auto;
    object-fit: cover;
}

.container {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.secondRowContainer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.secondRowContainer p {
    font-size: 14px;
    color: var(--gray);
    padding-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
    .firstRow {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        max-width: 93%;
        padding: 0;
    }
    .secondRow {
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    }
    .secondRow > img {
        width: 120px;
    }
}
@media only screen and (max-width: 768px) {
    .firstRow {
        grid-template-columns: unset;
    }
    .secondRow {
        grid-template-columns: unset;
    }

    .container {
        flex-direction: row;
        max-width: 80%;
        margin: auto;
    }
}
@media only screen and (max-width: 768px) {
    .firstRow > img {
        width: 100px;
    }
    .secondRow > img {
        width: 100px;
    }

    .container {
        flex-direction: row;
        max-width: 55%;
        margin: auto;
        padding: 0 0 50px;
    }
}
@media only screen and (max-width: 576px) {
    .container {
        max-width: 95%;
    }
}
