.slideContainer > img {
  display: block;
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  animation: contentAnimation 1.5s ease-in-out;
}
@keyframes contentAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content h2 {
  color: white;
  font-size: 50px;
  letter-spacing: 3px;
  text-shadow: 0px 0px 5px #00000075;
}
.content p {
  text-align: center;
  color: white;
  font-size: 30px;
  letter-spacing: 1px;
  text-shadow: 0px 0px 5px #00000075;
}

@media only screen and (max-width: 992px) {
  .content h2 {
    font-size: 40px;
  }
  .content p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .content h2 {
    font-size: 30px;
  }
  .content p {
    font-size: 16px;
  }
}
