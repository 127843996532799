.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap: 2rem;
    max-width: 1200px;
    margin: auto;
    padding: 4rem 3rem;

    justify-items: center;
    align-items: center;
}

.card {
    height: 220px;
    width: 270px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s all;
    background-color: white;
}
.card:hover {
    transform: scale(1.03);
}

.card img {
    height: 160px;
    object-fit: cover;

    border-radius: 10px 10px 0 0;
}
.description {
    background: var(--blue);
    width: 100%;
    height: 50px;
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card p {
    font-size: 18px;
    line-height: 30px;
    color: #f7f7f7;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
}

@media only screen and (max-width: 768px) {
    .container {
        grid-template-columns: unset;
    }
    .card {
        max-width: 400px;
        width: 100%;
    }
}
@media only screen and (max-width: 576px) {
    .container {
        grid-template-columns: unset;
        padding: 3rem 2rem;
    }
}
