.header {
  text-align: center;
  padding-top: 60px;
  position: relative;
  padding-bottom: 50px;
}
.header h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  padding-bottom: 8px;
}

.header::after {
  content: "";
  position: absolute;
  background: var(--blue);
  width: 70px;
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.content {
  max-width: 75%;
  margin: auto;
}

.content p {
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin: 0 0 15px;
}

.content ul {
  list-style-type: unset;
  padding-left: 30px;
}

.content li {
  list-style-type: unset;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin: 0 0 15px;
}

.container {
  background-image: url("../../../public/static/background-company.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;
}
