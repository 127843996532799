:root {
  --blue: #287094;
  --black: #182333;
  --gray: #8d9297;
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  list-style-type: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: #f7f7f7;
  box-sizing: border-box;
}

/* Create a custom scrollbar appearance */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.041);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.152);
  border: 2px solid rgba(255, 255, 255, 0.644);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.292);
}
