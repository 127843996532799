.searchContainer {
  width: 320px;
  box-sizing: border-box;
  background-color: white;
  top: 50px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  z-index: 99;
  box-shadow: 0px 0px 7px 3px #e2e2e26e;
  gap: 1rem;
  position: absolute;
}

.searchInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.searchContainer input {
  height: 40px;
  flex: 1 1;
  box-sizing: border-box;
  padding: 0.8rem;
  outline: 0;
  border: 0;
  background: #f7f7f7;
}
.icon {
  width: 40px;
  height: 40px;
  background-color: var(--blue);
}
.icon svg {
  font-size: 28px;
  position: relative;
  top: 6px;
  left: 6px;
  color: white;
}

.productsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 32vh;
}
.results {
  overflow: auto;
  height: 100%;
}
.results a {
  margin-left: 0;
}

/* Create a custom scrollbar appearance */
.results::-webkit-scrollbar {
  width: 6px;
}

.results::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.041);
  border-radius: 10px;
}

.results::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.152);
  border: 1px solid rgba(255, 255, 255, 0.644);
  border-radius: 10px;
}

.results::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.292);
}

.product {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
  transition: 0.3s all;
}
.product:hover {
  background-color: #d0d0d0;
}
.productName {
  order: 2;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--black);
}
.productImage {
  width: fit-content;
}
.productImage > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
