.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.header {
  position: relative;
  padding-left: 30px;
}
.header::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 95%;
  left: 0;
  top: 0;
  background: var(--blue);
}
.header p {
  font-weight: 600;
  font-size: 26px;
  line-height: 46px;
  color: #182333;
}
.leftSide {
  width: 40%;
}
.rightSide {
  width: 40%;
}
.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px 10px;
  padding: 30px 0;
  width: 100%;
}
.image {
  width: 48%;
  height: 120px;
  background: #fff;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.elkalub {
  width: 54px;
  height: 50px;
}
.bechem {
  width: 66px;
  height: 50px;
}
.memolub {
  width: 221px;
  height: 40px;
}
.anderol {
  width: 170px;
  height: 46px;
}
.image:hover::before {
  content: attr(title);
  background-color: #151414;
  color: #fff;
  padding: 4px 8px;
  position: absolute;
  top: -40px; /* Adjust the value as needed */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s all;
  border-radius: 8px;
  padding: 3px 20px;
}

.image:hover::before {
  opacity: 1;
  visibility: visible;
}

.image:hover::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #151414 transparent transparent transparent;
}

@media screen and (max-width: 1200px) {
  .container {
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .leftSide {
    width: 48%;
  }
  .rightSide {
    width: 48%;
  }
  .header p {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .leftSide {
    width: 100%;
  }
  .rightSide {
    width: 100%;
  }
  .imagesContainer {
    flex-direction: column;
  }
  .image {
    width: 100%;
  }
  .memolub {
    width: 221px;
    height: 50px;
  }
  .anderol {
    width: 197px;
    height: 54px;
  }
}

@media screen and (max-width: 576px) {
  .header p {
    font-size: 20px;
    line-height: 27px;
  }
}
