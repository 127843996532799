.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 5rem auto 0;
}

/* left side */

.leftSideWrapper {
  width: 50%;
  background-color: var(--blue);
  height: 786px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

.leftSideContent {
  max-width: 500px;
}

/* right side */

.rightSide {
  width: 50%;
  margin-top: -80px;
  height: 786px;
}

.rightSide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detailTitle {
  margin-bottom: 2rem;
  position: relative;
  padding-left: 2rem;
}

.detailTitle::after {
  content: "";
  display: block;
  width: 6px;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.detailTitle h4 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #e3e3e3e6;
}

.detailTitle h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: white;
}

.content p {
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #e3e3e3e6;
  margin-bottom: 2rem;
}

.detailsDiv {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.detailsDiv:last-child {
  padding-top: 2rem;
}
.detailsDiv:last-child::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e3e3e32b;
  position: absolute;
  top: 0;
}

.detailsDiv .content h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 10px;
  color: white;
}

.detailsDiv .content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #e3e3e3e6;
}

.image {
  min-width: 75px;
  height: 75px;
  border: 3px solid white;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 40px;
  color: white;
}

@media only screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .leftSideWrapper {
    width: 100%;
    height: auto;
    padding: 3rem 4rem;
    box-sizing: border-box;
  }
  .leftSideContent {
    max-width: unset;
  }
  .rightSide {
    width: 100%;
    height: 50vh;
    margin-top: 0;
  }
}
@media only screen and (max-width: 576px) {
  .leftSideWrapper {
    padding: 2rem 1rem 1rem;
  }
  .detailTitle h2 {
    font-size: 24px;
  }
  .content p {
    font-size: 13px;
  }
  .detailsDiv .content h2 {
    font-size: 18px;
  }
  .detailsDiv .content p {
    font-size: 13px;
    margin-bottom: 1rem;
  }
  .image {
    display: none;
  }
  .rightSide {
    height: 40vh;
  }
  .detailTitle {
    padding-left: 1.5rem;
  }
}
