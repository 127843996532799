.introductionContainer {
  margin: 5rem auto 0;
  padding-inline: 5rem;
}
.introductionContainer h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--black);
  margin-bottom: 2rem;
  padding-left: 2rem;

  position: relative;
}
.introductionContainer h2::after {
  content: "";
  display: block;
  width: 6px;
  height: 100%;
  background-color: var(--blue);
  position: absolute;
  top: 0;
  left: 0;
}
.introductionContainer p {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--gray);
  margin-bottom: 1rem;
}
.introductionContainer p:nth-of-type(1) {
  text-indent: 3rem;
}
.introductionContainer span {
  font-weight: 600;
  color: var(--gray);
}
@media only screen and (max-width: 992px) {
  .introductionContainer {
    padding-inline: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .introductionContainer h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 576px) {
  .introductionContainer {
    margin: 3rem auto 0;
    padding-inline: 1rem;
  }
  .introductionContainer h2 {
    font-size: 18px;
  }
  .introductionContainer p {
    font-size: 13px;
  }
}
