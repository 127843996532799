.footerContainer {
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px 0;
}

.imageContainer img {
  width: auto;
  height: 90px;
  position: relative;
}

.imageContainer {
  width: auto;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imageContainer::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 36px;
  background: #fff;
  opacity: 0.5;
  top: 29px;
  left: -15px;
}

.logo {
  width: 90px;
  height: 90px;
  background: radial-gradient(
    circle at center,
    #ffffff 21%,
    #dadada,
    #a9a9a9,
    #ffffff
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24px 6px #10304061;
}
.logo img {
  width: 80px;
  object-fit: contain;
}

@media only screen and (max-width: 992px) {
  .logo {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }
  .logo img {
    width: 50px;
  }
  .imageContainer img {
    height: 60px;
  }

  .imageContainer {
    height: 60px;
  }
  .imageContainer::before {
    top: 12px;
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    width: 50px;
    min-width: 50px;
    height: 50px;
  }
  .logo img {
    width: 40px;
  }
  .imageContainer img {
    height: 50px;
  }

  .imageContainer {
    height: 50px;
  }
  .imageContainer::before {
    top: 6px;
  }
}
